.CreativePreview__EmbedWrapper {
	background: black;
	border: solid 2px #979797;
	border-radius: 16px;
	height: 286px;
  padding: 10px;
  width: 100%;
  max-width: 493px;
  margin: 0 auto;
}

.CreativePreview__EmbedWrapper.portrait {
  height: 533px;
  width: 324px;
  margin: 0 auto;
	margin-bottom: 15px;
}

.CreativePreview__Embed {
  height: 100%;
  background: none;
  border: none;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 950px) {
  .CreativePreview__EmbedWrapper.landscape {
    display: inherit;
  }
}
