th.sorted.ascending:after {
  content: '\f0dd';
}

th.sorted.descending:after {
  content: '\f0de';
}

th.sortable:after {
  font-family: "Icons";
  margin-left: .3em;
  content: '\f0dc';
}

th:not(.ascending):not(.descending):after {
  opacity: .2;
}
