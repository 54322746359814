.addFeatureFlags .ui.celled.grid>.row>.column.companiesList{
  max-height: 300px;
  overflow: overlay;
  padding: 0px;
}

.addFeatureFlags > .ui.centered.grid {
  margin: auto;
}

.addFeatureFlags .header{
  border-bottom: 1px solid rgba(34,36,38,.15) ;
}
.header .ui.header.header__tittle{
  margin: 5px 0px;
}

.flagSelector{
  max-width: 500px;
}

.companiesSelector {
  max-width: 800px;
}

.ui.list>.item:hover {
  background: #f5f5f5;
}

.addFeatureFlags .companiesList .ui.list>.item,
.addFeatureFlags .companiesList .ui.list>.item:first-child
{
  padding: 10px;
}

.companiesSelector .ui.button.button__icon{
  background: transparent;
  padding: 0px 5px;
}

.companiesSelector .ui.form .inline.field:not(.wide) .ui.input{
  width: 100%;
}

.companiesSelector .footer{
  text-align: left;
}
