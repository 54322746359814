.s2sTrackingContainer {
  padding: 10px;
  min-width: 400px;
}

.trackingStatusResponse {
  margin-top: 20px;
}

.ui.fluid.card.trackingAppCard {
  margin-top: 40px;
}
