@media only screen and (min-width: 1440px) {
  .IntegrationsLayout {
      width: 90vw !important;
  }
}

.IntegrationsLayout .ui.toggle.checkbox input:checked~.box:before,
.IntegrationsLayout .ui.toggle.checkbox input:checked~label:before {
  background-color: #21ba45 !important;
}
