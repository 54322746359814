#CBExchangeOverview.container{
  width: auto;
}

#CBExchangeOverview .place-holder {
  height: 50vh;
  position: relative;
}

#CBExchangeOverview .place-holder .header {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

#CBExchangeOverview .ui.tabular.menu {
  border-bottom: none;
  margin-bottom: 30px;
}

#CBExchangeOverview .ui.tabular.menu .active.item {
  background: grey;
  border-color: transparent;
  margin-bottom: initial;
}

#CBExchangeOverview .ui.tabular.menu .item {
  background: #fff;
  margin-right: 2px;
  border-radius: .28571429rem .28571429rem 0 0!important;
}

#CBExchangeOverview .ui.table .icon{
  cursor: pointer;
}

#CBExchangeOverview .ui.table a.icon-link{
  color: initial;
}

#CBExchangeOverview .item-id {
  overflow: hidden;
  text-overflow: ellipsis;
}

#CBExchangeBlockView > .ui.segment{
  height: 70vh;
}

#CBExchangeBlockView > .ui.segment > .ui.grid,
#CBExchangeBlockView > .ui.segment > .ui.grid .row,
#CBExchangeBlockView .apps-selected-list {
  height: 100%;
}

#CBExchangeBlockView .apps-selected-list {
  overflow: auto;
}

#CBExchangeBlockView .editionOptions{
  display: flex;
  justify-content: center;
  align-items: center;
}

#CBExchangeBlockView .title {
  color: white;
}

#CBExchangeBlockView .option-buttons {
  justify-content: center;
}

#CBExchangeBlockView .edit-panel {
  padding: 10px 30px;
}

#CBExchangeBlockView .CBExchangeEdit {
  height: 100%;
}

#CBExchangeBlockView .item-warning {
  display: inline-block;
  position: relative;
}

#CBExchangeBlockView .item-warning .ui.label {
  position: absolute;
  left: -16px;
  top: 10px;
  width: 160px;
  display: none;
  z-index: 1;
}
#CBExchangeBlockView .item-warning .ui.pointing.label:before{
  left: 14%;
}

#CBExchangeBlockView .item-warning .ui.label.label--active {
  display: block;
}

#CBExchangeBlockView .added-id {
  overflow: hidden;
  word-break: break-all;
}

#CBExchangeSummaryView .check-icon {
  margin: 25px;
}

#CBExchangeSummaryView .copy-message {
  margin-top: 30px;
  cursor: pointer;
}

#CBExchangeSummaryView .list-ids .item-id {
  word-break: break-all;
}
