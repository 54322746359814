.CreativeValidation__Wrapper {
  display: flex;
  flex-direction: column;
}

.CreativePreview__Modal {
  min-width: 330px;
}

.CreativeValidation__Inputs {
  display: flex;
  flex-wrap: wrap;
}

.CreativeValidation__IdCell {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CreativeValidation__Search,
.CreativeValidation__Dropdown {
  width: 300px;
  margin: 0px 20px 20px 0px;
}

.CreativeValidation__StatusDropdown.ui.selection.dropdown {
  height: 22px;
  min-height: 38px;
  box-sizing: border-box;
  width: 200px;
}

.CreativeValidation__StatusDropdown,
.CreativeValidation__StatusDropdown .text {
  text-transform: capitalize;
}

.CreativeValidation__Search .input .prompt {
  width: 450px;
  border-radius: 4px;
}

.CreativePreview__EmbedWrapper {
	background: black;
	border: solid 2px #979797;
	border-radius: 16px;
	height: 286px;
	padding: 10px;
	width: 493px;
}

.CreativePreview__EmbedWrapper.portrait {
  height: 493px;
  width: 286px;
}

.CreativePreview__Embed {
  height: 100%;
  background: none;
  border: none;
  width: 100%;
  overflow: hidden;
}

.CreativePreview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ui.modal > .CreativePreview__Header.header {
	background-color: #EFEFEF;
	display: flex;
	height: 74px;
	justify-content: space-between;
	line-height: 1;
}

.CreativePreview__HeaderTitle {
  font-size: 16px;
}

.CreativePreview__Header span {
  flex: 1;
  max-width: 50%;
}

.CreativePreview__Header p {
  font-size: 22px;
  font-weight: lighter;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  white-space: nowrap;
  overflow-x: hidden;
}

.CreativePreview__Close {
  background: none;
	border: none;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s ease-in;
}

.CreativePreview__Close:hover {
  opacity: 1;
}

.CreativePreview__RightPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.CreativePreview__InfoTitle {
  color: #3B434A;
  font-weight: bold;
  margin-right: 15px;
}

.CreativePreview__Info {
  width: 100%;
}

.CreativePreview__SaveButton {
  float: right;
}

.CreativeValidation__Wrapper .ui.structured.celled.table tr td,
.CreativeValidation__Wrapper .ui.structured.celled.table tr th {
  border-left: none;
  border-right: none;
  flex: 1;
}

#chartboost-global-screen {
  overflow: hidden;
}

@media only screen and (max-width: 950px) {
  .CreativePreview {
    flex-direction: column-reverse;
    align-items: center;
  }
  .CreativeValidation__TableRow {
    display: flex;
  }
  .CreativeValidation__TableRow td {
    text-align: center;
  }
  .CreativeValidation__Inputs {
    margin-bottom: 40px;
  }
  .CreativeValidation__Inputs .CreativeValidation__Dropdown,
  .CreativeValidation__Search{
    width: 100%;
    margin-right: 0px;
  }
  .ui.modal.transition.visible.active.CreativePreview__Modal {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px!important;
    border-radius: 0px;
  }
  .CreativePreview__RightPanel {
    width: 100%;
  }
  .CreativePreview__Info {
    margin: 0px auto 40px auto;
  }
  .CreativePreview__EmbedWrapper.landscape {
    display: none;
  }
}
