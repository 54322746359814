.ui.table.cb-table {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.table_options{
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}
.table_wrapper{
  overflow: overlay;
}

.ui.sortable.table.cb-table thead th{
  border-left: none;
}
.ui.sortable.table.cb-table thead th.sorted {
  background: #f9fafb;
}

.ui.basic.primary.button.delete, .ui.basic.primary.button.delete:hover{
  box-shadow: none !important;
  color: red !important;
  text-decoration: underline;
  padding-left: 2.2em!important;
}
.ui.basic.primary.button.delete>.icon {
  background-color: transparent;
}
