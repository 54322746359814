.ui.segment.cb.users-wraper{
  padding: 0;
}
.cb.users-wraper > .ui.grid{
  min-height: 80vh;
}

.cb.users-wraper .ui.grid .column.sidebar{
  background-color: #f5f5f5;
  padding-left: 0;
  padding-right: 0;
  padding-top: 2.5em;
}
.cb.users-wraper .sidebar__title{
  color: #757575;
  font-weight: normal;
  margin: 20px;
}
.cb.users-wraper .ui.menu .sidebar__item{
  background: transparent;
}

.cb.users-wraper .ui.menu .sidebar__item.active{
  border: none;
}
.cb.users-wraper .ui.menu .sidebar__item a{
  color: #616161;
}

.cb.users-wraper .ui.grid .column.content{
  padding: 0;
}

.cb.users-wraper .ui.vertical.menu .item>i.icon, 
.cb.users-wraper .ui.vertical.menu .item>i.icons{
  float: left;
  margin: 0 .25rem 0 0;
}

.cb.users-wraper .ui.vertical.tabular.menu .item,
.cb.users-wraper .ui.vertical.tabular.menu .active.item {
  color: #616161;
}

@media only screen and (min-width: 1200px) {
  .cb.users-wraper .content .ui.container {
    width: initial;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}
