.orientation_title {
  font-weight: bold;
}

.asset_item {
  margin: 6px 0;
}

.asset_size {
  text-transform: capitalize;
  font-size: 13px;
  font-weight: bold;
}

.asset_url {
  word-break: break-word;
}

.pointer {
  cursor: pointer;
}
