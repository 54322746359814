.Header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: grid;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #4a5664;
  box-shadow: 0 0 2em #1a222c;
  padding: 0 16px;
  grid-template-columns: [first] 35% auto [last] 35%;
}

.Header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  height: 100%;
}

.Header-right {
  display: flex;
  justify-content: flex-end;
}

.Header-logo {
  margin: 0 auto;
  height: 25px;
}
