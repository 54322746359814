.Shell {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100vh;
  overflow-x: hidden;
}

.Shell.Public {
  background: #fff;
}

.Container {
  margin-top: 60px;
  min-height: 100vh;
  overflow: visible;
  padding: 5px 0 50px;
}

.Public .Container {
  margin-top: 0;
  padding: 0;
}

.Version {
  position: absolute;
  bottom: 0;
  right: 0.5em;
  font-size: 10px;
  color: rgba(255,255,255,0.25);
}

@media only screen and (min-width: 1440px) {
  .Container {
      width: 90vw !important;
  }
  .Public .Container {
      width: 99vw !important;
  }
}
