.CBExchangeEdit .idsList {
  min-height: 20vh;
  max-height: 20vh;
  overflow: auto;
}
.CBExchangeEdit .ui.button.button__icon{
  background: transparent;
  padding: 0px 5px;
}

.CBExchangeEdit .adminComment .ui.header {
  margin-top: 80px;
  margin-bottom: 12px;
}

.CBExchangeEdit .ui.form textarea {
  margin-bottom: 12px;
}

#CBExchangeBlockView .title{
  text-transform: capitalize;
  margin-top: 7px;
}
