.PublisherList__Search {
  float: right;
}

.PublisherList__Search input {
  width: 320px;
}

.labelLink {
  opacity: 1 !important;
  color: blue !important;
}
