.platform_checks {
  margin: 0 0 0 18px;
}

.platform_checks h4 {
  margin: 0;
}

.platform_name {
  text-transform: capitalize;
}

.platform_advice {
  font-style: italic;
}

.platform_checks i.check {
  color: #58bf58;
}
