.UserPendingList .ui.basic.primary.button, 
.UserPendingList .ui.basic.primary.buttons .button {
  box-shadow: none !important;
  text-decoration: underline;
  color: #4caf50 !important;
  padding-left: 2.2em !important;
}

.UserPendingList .ui.basic.primary.button:hover {
  box-shadow: none !important;
  color: #258e29 !important;
}

.UserPendingList .ui.basic.primary.button i {
  background: transparent;
}
