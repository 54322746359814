.filters{
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.filters .ui.form .inline.field .ui.input.prompt input{
  border-radius: 50px;
}
.filters .ui.form .inline.field .ui.input.prompt{
  width: 100%;
}

.filters .ui.selection.dropdown.featureFlagSelector {
  margin-left: 20px;
  margin-right: 20px;
}

.filters__left {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.filters .search {
  width: 50%;
}

.warningFusionFlagMessage {
  display: flex;
  margin: 0 20px 0 8px;
  color: rgb(241, 68, 0);
  font-size: 15px;
}
