#CreativeFinder .checkbox {
  margin-top: 15px;
}

#CreativeFinder .checkbox label {
  color: white !important;
}


#CreativeFinder .checkbox label:before {
  background: white;
}
