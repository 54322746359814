.app-card-managers {
  color: #000000b3;
}

.app-card-managers .header {
  color: #000000de;
  font-weight: 700;
}

.app-card-managers .header:not(:first-child) {
  margin-left: 4px;
}
