.InlineEdit__value {
  float: left;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 48px);
}

.Placeholder {
  color: white;
  display: table-cell;
  font-family: monospace;
  text-align: center;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
}

@media only screen and (min-width: 1440px) {
  .IntegrationsLayout {
      width: 90vw !important;
  }
}

.IntegrationsLayout .ui.toggle.checkbox input:checked~.box:before,
.IntegrationsLayout .ui.toggle.checkbox input:checked~label:before {
  background-color: #21ba45 !important;
}

.ui.progress .bar {
  min-width: 0;
  border-radius: .1337rem;
}

.PublisherList__Search {
  float: right;
}

.PublisherList__Search input {
  width: 320px;
}

.labelLink {
  opacity: 1 !important;
  color: blue !important;
}

.CreativeValidation__Wrapper {
  display: flex;
  flex-direction: column;
}

.CreativePreview__Modal {
  min-width: 330px;
}

.CreativeValidation__Inputs {
  display: flex;
  flex-wrap: wrap;
}

.CreativeValidation__IdCell {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CreativeValidation__Search,
.CreativeValidation__Dropdown {
  width: 300px;
  margin: 0px 20px 20px 0px;
}

.CreativeValidation__StatusDropdown.ui.selection.dropdown {
  height: 22px;
  min-height: 38px;
  box-sizing: border-box;
  width: 200px;
}

.CreativeValidation__StatusDropdown,
.CreativeValidation__StatusDropdown .text {
  text-transform: capitalize;
}

.CreativeValidation__Search .input .prompt {
  width: 450px;
  border-radius: 4px;
}

.CreativePreview__EmbedWrapper {
	background: black;
	border: solid 2px #979797;
	border-radius: 16px;
	height: 286px;
	padding: 10px;
	width: 493px;
}

.CreativePreview__EmbedWrapper.portrait {
  height: 493px;
  width: 286px;
}

.CreativePreview__Embed {
  height: 100%;
  background: none;
  border: none;
  width: 100%;
  overflow: hidden;
}

.CreativePreview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ui.modal > .CreativePreview__Header.header {
	background-color: #EFEFEF;
	display: flex;
	height: 74px;
	justify-content: space-between;
	line-height: 1;
}

.CreativePreview__HeaderTitle {
  font-size: 16px;
}

.CreativePreview__Header span {
  flex: 1 1;
  max-width: 50%;
}

.CreativePreview__Header p {
  font-size: 22px;
  font-weight: lighter;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  white-space: nowrap;
  overflow-x: hidden;
}

.CreativePreview__Close {
  background: none;
	border: none;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s ease-in;
}

.CreativePreview__Close:hover {
  opacity: 1;
}

.CreativePreview__RightPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.CreativePreview__InfoTitle {
  color: #3B434A;
  font-weight: bold;
  margin-right: 15px;
}

.CreativePreview__Info {
  width: 100%;
}

.CreativePreview__SaveButton {
  float: right;
}

.CreativeValidation__Wrapper .ui.structured.celled.table tr td,
.CreativeValidation__Wrapper .ui.structured.celled.table tr th {
  border-left: none;
  border-right: none;
  flex: 1 1;
}

#chartboost-global-screen {
  overflow: hidden;
}

@media only screen and (max-width: 950px) {
  .CreativePreview {
    flex-direction: column-reverse;
    align-items: center;
  }
  .CreativeValidation__TableRow {
    display: flex;
  }
  .CreativeValidation__TableRow td {
    text-align: center;
  }
  .CreativeValidation__Inputs {
    margin-bottom: 40px;
  }
  .CreativeValidation__Inputs .CreativeValidation__Dropdown,
  .CreativeValidation__Search{
    width: 100%;
    margin-right: 0px;
  }
  .ui.modal.transition.visible.active.CreativePreview__Modal {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px!important;
    border-radius: 0px;
  }
  .CreativePreview__RightPanel {
    width: 100%;
  }
  .CreativePreview__Info {
    margin: 0px auto 40px auto;
  }
  .CreativePreview__EmbedWrapper.landscape {
    display: none;
  }
}

.app-card-managers {
  color: #000000b3;
}

.app-card-managers .header {
  color: #000000de;
  font-weight: 700;
}

.app-card-managers .header:not(:first-child) {
  margin-left: 4px;
}

.ui.compact.table td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.platform_checks {
  margin: 0 0 0 18px;
}

.platform_checks h4 {
  margin: 0;
}

.platform_name {
  text-transform: capitalize;
}

.platform_advice {
  font-style: italic;
}

.platform_checks i.check {
  color: #58bf58;
}

th.sorted.ascending:after {
  content: '\F0DD';
}

th.sorted.descending:after {
  content: '\F0DE';
}

th.sortable:after {
  font-family: "Icons";
  margin-left: .3em;
  content: '\F0DC';
}

th:not(.ascending):not(.descending):after {
  opacity: .2;
}


.PrivateMarketplaceList.ui.container, .PrivateMarketplaceList.ui.container .ui.container {
  width: 100%;
}

.required-label::after{
  color: #db2828;
  margin: -.2em 0 0 .2em;
  content: '*';
}


.mb-1 {
  margin-bottom: 1em !important;
}

.default-field {
  font-size: .92857143em;
}

.Public .ui.container.PrivateMarketplaceEdit {
  width: 90%;
}

.ui.table.cb-table {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.table_options{
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}
.table_wrapper{
  overflow: overlay;
}

.ui.sortable.table.cb-table thead th{
  border-left: none;
}
.ui.sortable.table.cb-table thead th.sorted {
  background: #f9fafb;
}

.ui.basic.primary.button.delete, .ui.basic.primary.button.delete:hover{
  box-shadow: none !important;
  color: red !important;
  text-decoration: underline;
  padding-left: 2.2em!important;
}
.ui.basic.primary.button.delete>.icon {
  background-color: transparent;
}

.action-links > a {
    margin-right: 10px;
}
.ui.segment.cb.users-wraper{
  padding: 0;
}
.cb.users-wraper > .ui.grid{
  min-height: 80vh;
}

.cb.users-wraper .ui.grid .column.sidebar{
  background-color: #f5f5f5;
  padding-left: 0;
  padding-right: 0;
  padding-top: 2.5em;
}
.cb.users-wraper .sidebar__title{
  color: #757575;
  font-weight: normal;
  margin: 20px;
}
.cb.users-wraper .ui.menu .sidebar__item{
  background: transparent;
}

.cb.users-wraper .ui.menu .sidebar__item.active{
  border: none;
}
.cb.users-wraper .ui.menu .sidebar__item a{
  color: #616161;
}

.cb.users-wraper .ui.grid .column.content{
  padding: 0;
}

.cb.users-wraper .ui.vertical.menu .item>i.icon, 
.cb.users-wraper .ui.vertical.menu .item>i.icons{
  float: left;
  margin: 0 .25rem 0 0;
}

.cb.users-wraper .ui.vertical.tabular.menu .item,
.cb.users-wraper .ui.vertical.tabular.menu .active.item {
  color: #616161;
}

@media only screen and (min-width: 1200px) {
  .cb.users-wraper .content .ui.container {
    width: initial;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

.filters{
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.filters .ui.form .inline.field .ui.input.prompt input{
  border-radius: 50px;
}
.filters .ui.form .inline.field .ui.input.prompt{
  width: 100%;
}

.filters .ui.selection.dropdown.featureFlagSelector {
  margin-left: 20px;
  margin-right: 20px;
}

.filters__left {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.filters .search {
  width: 50%;
}

.warningFusionFlagMessage {
  display: flex;
  margin: 0 20px 0 8px;
  color: rgb(241, 68, 0);
  font-size: 15px;
}

.addFeatureFlags .ui.celled.grid>.row>.column.companiesList{
  max-height: 300px;
  overflow: overlay;
  padding: 0px;
}

.addFeatureFlags > .ui.centered.grid {
  margin: auto;
}

.addFeatureFlags .header{
  border-bottom: 1px solid rgba(34,36,38,.15) ;
}
.header .ui.header.header__tittle{
  margin: 5px 0px;
}

.flagSelector{
  max-width: 500px;
}

.companiesSelector {
  max-width: 800px;
}

.ui.list>.item:hover {
  background: #f5f5f5;
}

.addFeatureFlags .companiesList .ui.list>.item,
.addFeatureFlags .companiesList .ui.list>.item:first-child
{
  padding: 10px;
}

.companiesSelector .ui.button.button__icon{
  background: transparent;
  padding: 0px 5px;
}

.companiesSelector .ui.form .inline.field:not(.wide) .ui.input{
  width: 100%;
}

.companiesSelector .footer{
  text-align: left;
}

.UserPendingList .ui.basic.primary.button, 
.UserPendingList .ui.basic.primary.buttons .button {
  box-shadow: none !important;
  text-decoration: underline;
  color: #4caf50 !important;
  padding-left: 2.2em !important;
}

.UserPendingList .ui.basic.primary.button:hover {
  box-shadow: none !important;
  color: #258e29 !important;
}

.UserPendingList .ui.basic.primary.button i {
  background: transparent;
}

#CreativeFinder .checkbox {
  margin-top: 15px;
}

#CreativeFinder .checkbox label {
  color: white !important;
}


#CreativeFinder .checkbox label:before {
  background: white;
}

.orientation_title {
  text-transform: capitalize;
  font-weight: bold;
}

#creativeCard .metamarkets-button {
  margin-top: 20px;
  margin-bottom: 15px;
}

#adm-copy {
  cursor: pointer;
  margin-left: 5px;
}

.campaignsList {
  max-height: 250px;
  overflow: auto;
}

.campaignID {
  display: block;
}

.campaignsIDsHeader {
  display: flex;
}

.campaignsIDsHeader h3 {
  margin-bottom: 0;
  margin-right: 10px;
}

.campaignsIDsHeader i {
  cursor: pointer;
  position: relative;
  top: 1px;
}

.status--active {
  color: #B6EA6E;
}

.status--paused {
  color: #E25554;
}

#campaignIDs {
  max-height: 100px;
  overflow: auto;
}

.CreativePreview__EmbedWrapper {
	background: black;
	border: solid 2px #979797;
	border-radius: 16px;
	height: 286px;
  padding: 10px;
  width: 100%;
  max-width: 493px;
  margin: 0 auto;
}

.CreativePreview__EmbedWrapper.portrait {
  height: 533px;
  width: 324px;
  margin: 0 auto;
	margin-bottom: 15px;
}

.CreativePreview__Embed {
  height: 100%;
  background: none;
  border: none;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 950px) {
  .CreativePreview__EmbedWrapper.landscape {
    display: inherit;
  }
}

.orientation_title {
  font-weight: bold;
}

.asset_item {
  margin: 6px 0;
}

.asset_size {
  text-transform: capitalize;
  font-size: 13px;
  font-weight: bold;
}

.asset_url {
  word-break: break-word;
}

.pointer {
  cursor: pointer;
}

#CBExchangeOverview.container{
  width: auto;
}

#CBExchangeOverview .place-holder {
  height: 50vh;
  position: relative;
}

#CBExchangeOverview .place-holder .header {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

#CBExchangeOverview .ui.tabular.menu {
  border-bottom: none;
  margin-bottom: 30px;
}

#CBExchangeOverview .ui.tabular.menu .active.item {
  background: grey;
  border-color: transparent;
  margin-bottom: initial;
}

#CBExchangeOverview .ui.tabular.menu .item {
  background: #fff;
  margin-right: 2px;
  border-radius: .28571429rem .28571429rem 0 0!important;
}

#CBExchangeOverview .ui.table .icon{
  cursor: pointer;
}

#CBExchangeOverview .ui.table a.icon-link{
  color: initial;
}

#CBExchangeOverview .item-id {
  overflow: hidden;
  text-overflow: ellipsis;
}

#CBExchangeBlockView > .ui.segment{
  height: 70vh;
}

#CBExchangeBlockView > .ui.segment > .ui.grid,
#CBExchangeBlockView > .ui.segment > .ui.grid .row,
#CBExchangeBlockView .apps-selected-list {
  height: 100%;
}

#CBExchangeBlockView .apps-selected-list {
  overflow: auto;
}

#CBExchangeBlockView .editionOptions{
  display: flex;
  justify-content: center;
  align-items: center;
}

#CBExchangeBlockView .title {
  color: white;
}

#CBExchangeBlockView .option-buttons {
  justify-content: center;
}

#CBExchangeBlockView .edit-panel {
  padding: 10px 30px;
}

#CBExchangeBlockView .CBExchangeEdit {
  height: 100%;
}

#CBExchangeBlockView .item-warning {
  display: inline-block;
  position: relative;
}

#CBExchangeBlockView .item-warning .ui.label {
  position: absolute;
  left: -16px;
  top: 10px;
  width: 160px;
  display: none;
  z-index: 1;
}
#CBExchangeBlockView .item-warning .ui.pointing.label:before{
  left: 14%;
}

#CBExchangeBlockView .item-warning .ui.label.label--active {
  display: block;
}

#CBExchangeBlockView .added-id {
  overflow: hidden;
  word-break: break-all;
}

#CBExchangeSummaryView .check-icon {
  margin: 25px;
}

#CBExchangeSummaryView .copy-message {
  margin-top: 30px;
  cursor: pointer;
}

#CBExchangeSummaryView .list-ids .item-id {
  word-break: break-all;
}

.CBExchangeEdit .idsList {
  min-height: 20vh;
  max-height: 20vh;
  overflow: auto;
}
.CBExchangeEdit .ui.button.button__icon{
  background: transparent;
  padding: 0px 5px;
}

.CBExchangeEdit .adminComment .ui.header {
  margin-top: 80px;
  margin-bottom: 12px;
}

.CBExchangeEdit .ui.form textarea {
  margin-bottom: 12px;
}

#CBExchangeBlockView .title{
  text-transform: capitalize;
  margin-top: 7px;
}

.s2sTrackingContainer {
  padding: 10px;
  min-width: 400px;
}

.trackingStatusResponse {
  margin-top: 20px;
}

.ui.fluid.card.trackingAppCard {
  margin-top: 40px;
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: grid;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #4a5664;
  box-shadow: 0 0 2em #1a222c;
  padding: 0 16px;
  grid-template-columns: [first] 35% auto [last] 35%;
}

.Header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 16px;
  gap: 16px;
  height: 100%;
}

.Header-right {
  display: flex;
  justify-content: flex-end;
}

.Header-logo {
  margin: 0 auto;
  height: 25px;
}

.Navbar {
  height: 100vh;
}

.Shell {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100vh;
  overflow-x: hidden;
}

.Shell.Public {
  background: #fff;
}

.Container {
  margin-top: 60px;
  min-height: 100vh;
  overflow: visible;
  padding: 5px 0 50px;
}

.Public .Container {
  margin-top: 0;
  padding: 0;
}

.Version {
  position: absolute;
  bottom: 0;
  right: 0.5em;
  font-size: 10px;
  color: rgba(255,255,255,0.25);
}

@media only screen and (min-width: 1440px) {
  .Container {
      width: 90vw !important;
  }
  .Public .Container {
      width: 99vw !important;
  }
}

