.orientation_title {
  text-transform: capitalize;
  font-weight: bold;
}

#creativeCard .metamarkets-button {
  margin-top: 20px;
  margin-bottom: 15px;
}

#adm-copy {
  cursor: pointer;
  margin-left: 5px;
}
