.campaignsList {
  max-height: 250px;
  overflow: auto;
}

.campaignID {
  display: block;
}

.campaignsIDsHeader {
  display: flex;
}

.campaignsIDsHeader h3 {
  margin-bottom: 0;
  margin-right: 10px;
}

.campaignsIDsHeader i {
  cursor: pointer;
  position: relative;
  top: 1px;
}

.status--active {
  color: #B6EA6E;
}

.status--paused {
  color: #E25554;
}

#campaignIDs {
  max-height: 100px;
  overflow: auto;
}
